<!-- 菜单管理 -->
<template>
  <div class="account">
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>系统管理</el-breadcrumb-item>
          <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- content -->
      <div class="accountMain">
          <!-- table top -->
        <div class="accTableTop">
          <div class="accCreate" @click="addRole = true"><i class="el-icon-plus"></i>添加菜单</div>
        </div>
        <!-- table -->
        <MenuTable ref="roleTable"/>
        
      </div>
  </div>
</template>
<script>
import MenuTable from '@/components/table/menuTable.vue'
import {addRole,updateRole,insertRootTree} from '@/api/role.js'

export default {
    components:{
      MenuTable
    },
    data(){
      return{
        addRole:false,//add
        //addFORM
        addForm:{
            roleName:'',
            roleIndentity:'',
        },
        //rules
        addrule:{
          roleName: [
            { required: true, message: '请输入角色名称', trigger: 'blur' },
          ],
          roleIndentity: [
            { required: true, message: '请输入角色标识', trigger: 'blur' }
          ]
        },
        //查看
        viewdiog:false,
        viewForm:null,
        //编辑
        edite:false,
        ediForm:{
            name:'',
            indentity:''
        },
        editeRule:{
          name: [
            { required: true, message: '请输入角色名称', trigger: 'blur' },
          ],
          indentity: [
            { required: true,}
          ]
        },
        formLabelWidth: '90px',
        //root-tree
        addroot:false,
        treeData:null,
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        checkData:null,//默认选中数据
      }
    },

    methods:{
      //新增角色相关
      cancelAdd(formName){
        this.addRole = false;
        this.$refs[formName].resetFields();
      },
      sureAdd(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            addRole({
              authority: this.addForm.roleIndentity,
              name: this.addForm.roleName,
            }).then(res=>{
              if(res.data.status == 200){
                this.$notify({
                  message: '添加成功！',
                  type: 'success',
                  duration:800
                });
              this.$refs[formName].resetFields();
              this.addRole=false;
              this.$refs.roleTable.getData(1,10); //重心=新渲染列表
          } else {
                  this.$notify.error({
                  message:res.data.message
              });
            }
          }).catch(err=>{
              this.$message.error(err);
          })
          }
          else{
            this.$message.error("请重新输入！");
            return false;
          }
        });
      },
      //编辑
      ediCancel(formName){
        this.edite = false;
        //this.$refs[formName].resetFields();
      },
      ediSure(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            updateRole({
              id:this.$refs.roleTable.editId,
              name: this.ediForm.name,
            }).then(res=>{
              if(res.data.status == 200){
                this.$notify({
                  message: '修改成功！',
                  type: 'success',
                  duration:800
                });
            //this.$refs[formName].resetFields();
            this.edite=false;
             this.$refs.roleTable.getData(1,10); //重新渲染列表
          } else {
              this.$notify.error({
                  message:res.data.message
              });
            }
          }).catch(err=>{
            this.$message.error(err);
          })
          }
          else{
           this.$message.error("请重新输入！");
            return false;
          }
        });
      },
      //添加权限--模块
      rootCancel(){
        this.addroot = false;
      },
      rootSure(){
        const tem = this.$refs.tree.getCheckedKeys();//当前所选的节点
        //console.log(tem);
        const that = this;
        insertRootTree({
          id:that.$refs.roleTable.treeId,
          menus:tem
        }).then(res=>{
          if(res.data.status==200){
            this.$notify({
                  message: '添加权限成功！',
                  type: 'success',
                  duration:800
              });
           // tem=null;
            that.addroot=false;
          }else{
            this.$notify.error({
                message:res.data.message
            });
          }
        }).catch(err=>{
            this.$message.error(err);
          })
        
      }
    }
}
</script>

<style scoped lang="stylus">
//change elemen-ui style

//dalogform
/deep/.el-form-item__label{
    text-align:center !important;
    padding-left:0 !important;
    padding-right:0px !important;  
}
//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

  
//narmoal
.account  
  overflow hidden
  .accountMain
    overflow hidden
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
    .accTableTop
      display: flex
      .accCreate 
        width: 90px 
        height 32px 
        margin-right 12px
        background-color:#00CCCC
        border-radius: 2px
        cursor pointer
        color: #fff
        line-height: 32px 
        text-align: center
        font-size: 14px
      

</style>