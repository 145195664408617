//角色管理
import  request  from "@/utils/request";

// 角色管理--获取校色列表
export function getRoleList (parameter){
    return request({
       url:'/api/role/getRoleList',
       method:'post',
       data:parameter
   })
}
// 角色管理--新增
export function addRole (parameter){
    return request({
       url:'/api/role/insertRole',
       method:'post',
       data:parameter
   })
}

// 角色管理--删除
export function deleteRole (parameter){
    return request({
       url:'/api/role/deleteRole',
       method:'post',
       data:parameter
   })
}

// 角色管理--修改
export function updateRole (parameter){
    return request({
       url:'/api/role/updateRole',
       method:'post',
       data:parameter
   })
}

// 角色管理--查看角色详情
export function viewRole (parameter){
    return request({
       url:'/api/role/viewRole',
       method:'post',
       data:parameter
   })
}

// 角色管理--根据角色id获取树型菜单
export function getCurrentTree (parameter){
    return request({
       url:'/api/menu/getTreeByRoleId',
       method:'get',
       params:parameter
   })
}


// 角色管理--获取树型菜单
export function getAllTree (parameter){
    return request({
       url:'/api/menu/tree',
       method:'get',
       params:parameter
   })
}


// 角色管理--新增角色菜单
export function insertRootTree (parameter){
    return request({
       url:'/api/role/menu',
       method:'post',
       data:parameter
   })
}


